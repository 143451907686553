/*
 * This auth connector differs from the base Backstage connector since it implements
 * the session creation and refresh in the same way.
 * The only difference is that the create session sets the credentials as headers,
 * while the refresh (that takes the session as input in this implementation) extract the knwon
 * values and passes them as headers to the backend.
 */

import { AuthProviderInfo, DiscoveryApi } from '@backstage/core-plugin-api';
import { AuthConnector, GetSessionOptions } from './types';

type Options<AuthSession> = {
  /**
   * DiscoveryApi instance used to locate the auth backend endpoint.
   */
  discoveryApi: DiscoveryApi;
  /**
   * Environment hint passed on to auth backend, for example 'production' or 'development'
   */
  environment: string;
  /**
   * Information about the auth provider to be shown to the user.
   * The ID Must match the backend auth plugin configuration, for example 'google'.
   */
  provider: AuthProviderInfo;
  /**
   * Function used to transform an auth response into the session type.
   */
  sessionTransform?(response: any): AuthSession | Promise<AuthSession>;

  extractHeaders: (session: AuthSession) => any;
};

/**
 * DefaultAuthConnector is the default auth connector in Backstage. It talks to the
 * backend auth plugin through the standardized API, and requests user permission
 * via the OAuthRequestApi.
 */
export class LdapDefaultAuthConnector<AuthSession>
  implements AuthConnector<AuthSession>
{
  private readonly discoveryApi: DiscoveryApi;
  private readonly environment: string;
  private readonly provider: AuthProviderInfo;
  private readonly sessionTransform: (response: any) => Promise<AuthSession>;
  private readonly extractHeaders: (session: AuthSession) => any;

  constructor(options: Options<AuthSession>) {
    const {
      discoveryApi,
      environment,
      provider,
      extractHeaders,
      sessionTransform = id => id,
    } = options;

    this.discoveryApi = discoveryApi;
    this.environment = environment;
    this.provider = provider;
    this.sessionTransform = sessionTransform;
    this.extractHeaders = extractHeaders;
  }

  async createSession(options: GetSessionOptions): Promise<AuthSession> {
    const res = await fetch(await this.buildUrl('/start', { optional: true }), {
      headers: {
        'x-requested-with': 'XMLHttpRequest',
        Authorization: `Basic ${Buffer.from(
          `${options.username}:${options.password}`,
          'utf8',
        ).toString('base64')}`,
      },
      credentials: 'include',
    }).catch(error => {
      throw new Error(`Auth refresh request failed, ${error}`);
    });

    if (!res.ok) {
      let error: any;
      try {
        error = new Error(await res.text());
      } catch (inner) {
        error = new Error(res.statusText);
      }
      error.status = res.status;
      throw error;
    }

    const authInfo = await res.json();

    if (authInfo.error) {
      const error = new Error(authInfo.error.message);
      if (authInfo.error.name) {
        error.name = authInfo.error.name;
      }
      throw error;
    }
    return await this.sessionTransform(authInfo);
  }

  async refreshSession(session: AuthSession): Promise<any> {
    const headers = this.extractHeaders(session);
    const res = await fetch(
      await this.buildUrl('/refresh', { optional: true }),
      {
        headers: {
          'x-requested-with': 'XMLHttpRequest',
          ...headers,
        },
        credentials: 'include',
      },
    ).catch(error => {
      throw new Error(`Auth refresh request failed, ${error}`);
    });

    if (!res.ok) {
      let error: any;
      try {
        error = new Error(await res.text());
      } catch (inner) {
        error = new Error(res.statusText);
      }
      error.status = res.status;
      throw error;
    }

    const authInfo = await res.json();

    if (authInfo.error) {
      const error = new Error(authInfo.error.message);
      if (authInfo.error.name) {
        error.name = authInfo.error.name;
      }
      throw error;
    }
    return await this.sessionTransform(authInfo);
  }

  async removeSession(): Promise<void> {
    const res = await fetch(await this.buildUrl('/logout'), {
      method: 'POST',
      headers: {
        'x-requested-with': 'XMLHttpRequest',
      },
      credentials: 'include',
    }).catch(error => {
      throw new Error(`Logout request failed, ${error}`);
    });

    if (!res.ok) {
      let error: any;
      try {
        error = new Error(await res.text());
      } catch (inner) {
        error = new Error(res.statusText);
      }
      error.status = res.status;
      throw error;
    }
  }

  private async buildUrl(
    path: string,
    query?: { [key: string]: string | boolean | undefined },
  ): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('auth');
    const queryString = this.buildQueryString({
      ...query,
      env: this.environment,
    });

    return `${baseUrl}/${this.provider.id}${path}${queryString}`;
  }

  private buildQueryString(query?: {
    [key: string]: string | boolean | undefined;
  }): string {
    if (!query) {
      return '';
    }

    const queryString = Object.entries<string | boolean | undefined>(query)
      .map(([key, value]) => {
        if (typeof value === 'string') {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        } else if (value) {
          return encodeURIComponent(key);
        }
        return undefined;
      })
      .filter(Boolean)
      .join('&');

    if (!queryString) {
      return '';
    }
    return `?${queryString}`;
  }
}
