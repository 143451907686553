import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { mapToDeploys, useControlPanel } from '../useControlPanel';
import { Deploy } from '../types';
import { DeployComponent } from './DeployComponent';
import { format, parseISO } from 'date-fns';
import {
  ConfirmDialog,
  ProgressButton,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../api';
import {
  ReleaseEntityV1alpha1,
  TaskAction,
  deconstructVersion,
  generateURNByKind,
  isRunningStatus,
} from '@agilelab/plugin-wb-builder-common';
import { usePermission } from '@backstage/plugin-permission-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  builderDpCommitPermission,
  builderDpDeployPermission,
  builderDpReleasePermission,
} from '@agilelab/plugin-wb-rbac-common';
import { Skeleton } from '@material-ui/lab';
import yaml from 'yaml';

const useStyles = makeStyles(theme =>
  createStyles({
    statusWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    selected: {
      backgroundColor: `${theme.palette.bkg.primary}`,
      '&:hover': {
        backgroundColor: `${theme.palette.bkg.primary} !important`,
      },
    },
    purpleBorder: {
      position: 'relative',
      '&::before': {
        content: "''",
        height: '30px',
        width: '5px',
        backgroundColor: theme.palette.accent.main,
        position: 'absolute',
        top: '43px',
        left: 0,
      },
    },
    button: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    deployLoader: {
      paddingLeft: '45px',
      paddingBottom: theme.spacing(1),
    },
  }),
);

function compareVersions(
  releaseVersion: string | undefined,
  deployedVersion: string | undefined,
) {
  if (!releaseVersion || !deployedVersion) return false;

  const a = deconstructVersion(releaseVersion);
  const b = deconstructVersion(deployedVersion);

  return `${a.major}.${a.minor || 0}` === `${b.major}.${b.minor || 0}`;
}

interface Props {
  release: ReleaseEntityV1alpha1;
  isReleasing: boolean;
  isCommitting: boolean;
  setIsReleasing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCommitting: React.Dispatch<React.SetStateAction<boolean>>;
}

function Row(props: Props) {
  const {
    release,
    isReleasing,
    setIsReleasing,
    isCommitting,
    setIsCommitting,
  } = props;
  const theme = useTheme();
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState(false);
  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);

  const [deploys, setDeploys] = useState<Deploy[]>([]);
  const [limit, setLimit] = useState(3);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);

  const [onLoadingDeploys, setOnLoadingDeploys] = useState(false);

  const {
    releaseDeployingId,
    setReleaseDeployingId,
    releaseUndeployingId,
    setReleaseUndeployingId,
    addRelease,
    entity,
    environment,
    fetchPreviewDescriptor,
    fetchPreviewDescriptorState,
    setVersion,
    data,
  } = useControlPanel();

  const deconstructredVersion = deconstructVersion(release.metadata.version);
  const version = `${deconstructredVersion.major}.${
    deconstructredVersion.minor || 0
  }.${deconstructredVersion.patch || 0}`;
  const executionPlan = data?.coordinator_execution_plan[0];

  const isDeployedVersion =
    compareVersions(release.metadata.version, executionPlan?.version) &&
    !(
      executionPlan?.status === 'Completed' &&
      executionPlan?.operation === 'Unprovision'
    );

  const loadDeploys = useCallback(async () => {
    setOnLoadingDeploys(true);
    const loadedDeploys = await panelCatalog
      .getProvisioningPlansByDpIdAndEnvironment(
        generateURNByKind(entity.metadata.name, entity.kind),
        environment,
        false,
        true,
        {
          version,
          operations: [TaskAction.PROVISION, TaskAction.UNPROVISION],
          offset,
          limit,
        },
      )
      .then(result => {
        setCount(result.page.count);
        return mapToDeploys(result.provisioningPlans, entity);
      })
      .catch(_ => [])
      .finally(() => {
        setOnLoadingDeploys(false);
      });

    // Workaround to disable buttons if user deploys and quickly refresh the page
    const alreadyInRunning = loadedDeploys.find(d => isRunningStatus(d.status));
    if (alreadyInRunning) {
      if (
        alreadyInRunning.action === TaskAction.UNPROVISION ||
        alreadyInRunning.action === TaskAction.UNPROVISION_DATAPRODUCT
      ) {
        setReleaseUndeployingId(release.metadata.name);
      } else {
        setReleaseDeployingId(release.metadata.name);
      }
    }

    setDeploys(loadedDeploys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  useEffect(() => {
    loadDeploys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset]);

  const { allowed: canRelease } = usePermission({
    permission: builderDpReleasePermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const { allowed: canCommit } = usePermission({
    permission: builderDpCommitPermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const { allowed: canDeploy } = usePermission({
    permission: builderDpDeployPermission(environment),
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const handleDeploy = useCallback(async () => {
    setReleaseDeployingId(release.metadata.name);
    try {
      const deployResponse = await panelCatalog.deployRelease(
        release.metadata.name,
        release.metadata.dataProductName,
        environment,
        await identityApi.getCredentials(),
      );

      if (deployResponse.errors) {
        throw new Error(JSON.stringify(deployResponse.errors));
      }

      if (offset !== 0) {
        setOffset(0);
      } else loadDeploys();
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
      setReleaseDeployingId('');
    }
  }, [
    setReleaseDeployingId,
    release.metadata.name,
    release.metadata.dataProductName,
    panelCatalog,
    environment,
    identityApi,
    offset,
    loadDeploys,
    alertApi,
  ]);

  const handleUndeploy = useCallback(async () => {
    setReleaseUndeployingId(release.metadata.name);
    try {
      const deployResponse = await panelCatalog.undeployRelease(
        release.metadata.name,
        release.metadata.dataProductName,
        environment,
        await identityApi.getCredentials(),
      );

      if (deployResponse.errors) {
        throw new Error(JSON.stringify(deployResponse.errors));
      }

      if (offset !== 0) {
        setOffset(0);
      } else loadDeploys();
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
      setReleaseUndeployingId('');
    }
  }, [
    setReleaseUndeployingId,
    release.metadata.name,
    release.metadata.dataProductName,
    panelCatalog,
    environment,
    identityApi,
    offset,
    loadDeploys,
    alertApi,
  ]);

  const handleRelease = async () => {
    setIsReleasing(true);
    try {
      const newRelease = await panelCatalog.promoteToRelease(
        release.metadata.name,
        release.metadata.dataProductName,
        await identityApi.getCredentials(),
      );
      addRelease(newRelease);
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      // We are calling this function here in order to refresh Preview Descriptor window
      // with the latest version of the descriptor(latest version field particularly)
      await fetchPreviewDescriptor();
      setIsReleasing(false);
    }
  };

  useEffect(() => {
    if (
      !fetchPreviewDescriptorState.loading &&
      !fetchPreviewDescriptorState.error &&
      fetchPreviewDescriptorState.value &&
      entity
    ) {
      const parsedDescriptor = yaml.parse(fetchPreviewDescriptorState.value);
      if (parsedDescriptor.version !== entity.spec.mesh.version) {
        entity.spec.mesh.version = parsedDescriptor.version;
        setVersion(parsedDescriptor.version);
      }
    }
  }, [fetchPreviewDescriptorState, entity, setVersion]);

  const commitSnapshot = useCallback(async () => {
    if (!entity) return;
    setIsCommitting(true);

    try {
      const newRelease = await panelCatalog.commitSnapshot(
        entity.metadata.name,
        await identityApi.getCredentials(),
      );
      addRelease(newRelease);
      setOpenDialog(false);
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      // We are calling this function here in order to refresh Preview Descriptor window
      // with the latest version of the descriptor(latest version field particularly)
      await fetchPreviewDescriptor();
      setIsCommitting(false);
    }
  }, [
    entity,
    setIsCommitting,
    panelCatalog,
    identityApi,
    addRelease,
    alertApi,
    fetchPreviewDescriptor,
  ]);

  const isDeployed = useMemo(
    () =>
      isDeployedVersion &&
      deploys[0]?.steps[0].action !== TaskAction.UNPROVISION &&
      deploys[0]?.steps[0].action !== TaskAction.UNPROVISION_DATAPRODUCT,
    [deploys, isDeployedVersion],
  );

  const handleButtonCommit = useCallback(async () => {
    if (isDeployed) {
      setOpenDialog(true);
    } else {
      commitSnapshot();
    }
  }, [commitSnapshot, isDeployed]);

  const deployButton = useMemo(
    () => (
      <Tooltip
        title={
          canDeploy
            ? 'Deploy the selected release to the selected environment. This will start a provisioning operation consisting of multiple tasks. If all the tasks are successful, the selected release will be published in the marketplace.'
            : 'You are not allowed to deploy the selected release in the selected environment.'
        }
      >
        <ProgressButton
          onClick={handleDeploy}
          variant="contained"
          color="primary"
          size="small"
          inProgress={releaseDeployingId === release.metadata.name}
          className={classes.button}
          disabled={
            isReleasing ||
            isCommitting ||
            !!releaseDeployingId ||
            !!releaseUndeployingId ||
            !canDeploy
          }
        >
          Deploy
        </ProgressButton>
      </Tooltip>
    ),
    [
      classes.button,
      handleDeploy,
      isCommitting,
      isReleasing,
      release.metadata.name,
      releaseDeployingId,
      releaseUndeployingId,
      canDeploy,
    ],
  );

  const undeployButton = useMemo(
    () => (
      <Tooltip
        title={
          canDeploy
            ? 'Undeploy the selected release from the selected environment. This will start a provisioning operation consisting of multiple tasks. If all the tasks are successful, the selected release will be removed from the marketplace.'
            : 'You are not allowed to undeploy the selected release from the selected environment.'
        }
      >
        <ProgressButton
          onClick={handleUndeploy}
          variant="contained"
          color="secondary"
          size="small"
          inProgress={releaseUndeployingId === release.metadata.name}
          meshColor="red"
          className={classes.button}
          disabled={
            isReleasing ||
            isCommitting ||
            !!releaseDeployingId ||
            !!releaseUndeployingId ||
            !canDeploy
          }
        >
          Undeploy
        </ProgressButton>
      </Tooltip>
    ),
    [
      classes.button,
      handleUndeploy,
      isCommitting,
      isReleasing,
      release.metadata.name,
      releaseDeployingId,
      releaseUndeployingId,
      canDeploy,
    ],
  );

  const deployActions = useMemo(() => {
    if (isDeployedVersion) {
      return (
        <>
          {deployButton}
          {undeployButton}
        </>
      );
    }

    return deployButton;
  }, [isDeployedVersion, deployButton, undeployButton]);

  return (
    <>
      <TableRow className={isDeployedVersion ? classes.selected : ''}>
        <TableCell
          width="250px"
          className={release.metadata.isSnapshot ? classes.purpleBorder : ''}
        >
          <Box className={classes.statusWrapper}>
            <Typography
              style={{
                color: release.metadata.isSnapshot
                  ? theme.palette.accent.main
                  : theme.palette.success.main,
              }}
            >
              <strong>{`${release.metadata.version}`}</strong>
            </Typography>
            <Typography variant="caption">
              <strong>
                {release.metadata.isSnapshot
                  ? 'Snapshot committed '
                  : 'Release created '}
                at
              </strong>
            </Typography>
            <Typography variant="caption">
              {format(
                parseISO(
                  release.metadata.isSnapshot && release.metadata.committedAt
                    ? release.metadata.committedAt
                    : release.metadata.createdAt,
                ),
                'yyyy/MM/dd HH:mm:ss',
              )}
            </Typography>
          </Box>
        </TableCell>
        <TableCell width="40%">
          <Link
            href={
              release.metadata.annotations?.[
                'backstage.io/source-location'
              ].slice(4) || release.metadata.repoUrl
            }
            target="_blank"
            rel="noopener"
          >
            {release.metadata.annotations?.[
              'backstage.io/source-location'
            ].slice(4) || release.metadata.repoUrl}
          </Link>
        </TableCell>
        <TableCell>
          {deployActions}
          {release.metadata.isSnapshot && (
            <>
              <Tooltip
                title={
                  canRelease
                    ? 'Create a new release from the existing snapshot. This operation is like a commit operation, but creates an immutable release that cannot be updated anymore by performing additional commits.'
                    : 'You are not allowed to create new releases.'
                }
              >
                <ProgressButton
                  onClick={handleRelease}
                  variant="contained"
                  color="primary"
                  size="small"
                  meshColor="green"
                  inProgress={isReleasing}
                  className={classes.button}
                  disabled={
                    isCommitting ||
                    !canRelease ||
                    !!releaseDeployingId ||
                    !!releaseUndeployingId
                  }
                >
                  Release
                </ProgressButton>
              </Tooltip>
              <Tooltip
                title={
                  canCommit
                    ? 'Commit the changes made in the main repository branch to the existing snapshot files. The previous snapshot files will be overwritten by this operation, but you will be able to commit additional changes in the future.'
                    : 'You are not allowed to commit changes to this snapshot.'
                }
              >
                <ProgressButton
                  onClick={handleButtonCommit}
                  variant="contained"
                  color="primary"
                  size="small"
                  meshColor="purple"
                  inProgress={isCommitting}
                  className={classes.button}
                  disabled={
                    isReleasing ||
                    !canCommit ||
                    !!releaseDeployingId ||
                    !!releaseUndeployingId
                  }
                >
                  Commit
                </ProgressButton>
              </Tooltip>
              <ConfirmDialog
                title="Snapshot already deployed"
                description={
                  <span>
                    Do you want to lose{' '}
                    <strong style={{ color: theme.palette.accent.main }}>
                      snapshot {release.metadata.version}
                    </strong>{' '}
                    and make a new commit?
                  </span>
                }
                open={openDialog}
                onConfirm={commitSnapshot}
                onClose={() => setOpenDialog(false)}
                inProgress={isCommitting}
                confirmButtonText="Make new commit"
              />
            </>
          )}
        </TableCell>
      </TableRow>

      <>
        {onLoadingDeploys ? (
          <TableCell colSpan={5}>
            {new Array(limit).fill(0).map((_, index) => (
              <Box className={classes.deployLoader} key={index}>
                <Skeleton variant="rect" width="50%" height={70} />
              </Box>
            ))}
          </TableCell>
        ) : (
          <>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
              {deploys.map(d => (
                <DeployComponent
                  key={d.id}
                  deploy={d}
                  loadDeploys={loadDeploys}
                />
              ))}

              {count ? (
                <TablePagination
                  style={{
                    display: 'flex',
                  }}
                  rowsPerPageOptions={[3, 5, 10]}
                  component="div"
                  count={count}
                  rowsPerPage={limit}
                  page={offset / limit}
                  onPageChange={(_, page) => {
                    setOffset(page * limit);
                  }}
                  onRowsPerPageChange={e => {
                    const value = Number(e.target.value);
                    setOffset(0);
                    setLimit(value);
                  }}
                />
              ) : (
                <></>
              )}
            </TableCell>
          </>
        )}
      </>
    </>
  );
}

type ReleaseTableProps = {
  releases: ReleaseEntityV1alpha1[];
};

export function ReleaseTable(props: ReleaseTableProps) {
  const { releases } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const [isReleasing, setIsReleasing] = useState(false);
  const [isCommitting, setIsCommitting] = useState(false);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer style={{ flex: '1 1 0' }}>
      <Table aria-label="releases table">
        <TableHead>
          <TableRow>
            <TableCell>Release</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {releases
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(release => (
              <Row
                key={release.metadata.name}
                release={release}
                isReleasing={isReleasing}
                isCommitting={isCommitting}
                setIsCommitting={setIsCommitting}
                setIsReleasing={setIsReleasing}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={releases.length}
        page={page}
        rowsPerPageOptions={[3, 5, 10]}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
