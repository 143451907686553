import React, { useEffect, useState } from 'react';
import { DeploymentPreviewAccordion } from './DeploymentPreviewAccordion';
import { useDeploymentPreviewOperations } from '../hooks/useDeploymentPreviewOperations';
import { DeploymentPreviewTable } from './DeploymentPreviewTable';

interface Props {
  ids: string[];
}

export const NotInTargetDescriptorAccordion: React.FC<Props> = ({ ids }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { fetchPreviewOperations, previewOperations } =
    useDeploymentPreviewOperations(ids);

  useEffect(() => {
    if (expanded) fetchPreviewOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  if (ids.length === 0) return <></>;

  return (
    <DeploymentPreviewAccordion
      expanded={expanded}
      setExpanded={setExpanded}
      label={`${ids.length} components are not in the descriptor anymore.`}
      bold="They will be undeployed."
    >
      <DeploymentPreviewTable
        rows={previewOperations.value || []}
        loading={previewOperations.loading}
      />
    </DeploymentPreviewAccordion>
  );
};
