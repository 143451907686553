import { MonacoEditor } from './WbEditor';
import { Range } from 'monaco-editor';
import type monaco from 'monaco-editor';
import { IntRange, IntRanges } from '@agilelab/plugin-wb-platform-common';
import { Monaco } from '@monaco-editor/react';

interface Section {
  lines: IntRange;
  id: string;
}

interface SectionsVisitor {
  sections: Section[];
  main: boolean;
  id: string | undefined;
  startIdx: number;
}

export class WbMonacoEditor {
  private readonly editor: MonacoEditor;
  private readonly monaco: Monaco | undefined;

  constructor(editor: MonacoEditor, monaco?: Monaco) {
    this.editor = editor;
    this.monaco = monaco;
  }

  removeDecorations() {
    const allDecorations = this.editor.getModel()?.getAllDecorations();
    const decorationIds = allDecorations?.map(decoration => decoration.id);

    if (decorationIds) {
      this.editor.removeDecorations(decorationIds);
    }
  }

  applyDecorations(
    decorations?: monaco.editor.IModelDeltaDecoration[],
    removeAfterMsTimeout?: number,
    theme?: string,
  ) {
    // Clear previous decorations
    this.removeDecorations();

    // Apply theme name if provided
    if (theme) {
      this.monaco?.editor.setTheme(theme);
    }

    // Apply decorations if provided
    if (decorations) this.editor.createDecorationsCollection(decorations);

    // Remove decorations after timeout (if set)
    if (removeAfterMsTimeout) {
      setTimeout(() => {
        this.removeDecorations();
      }, removeAfterMsTimeout);
    }
  }

  /**
   * Applies the CSS class to all the lines passed as input
   * @param editor the monaco editor
   * @param lines the lines' indexes to highlight
   * @param highlightCssClass the CSS class to apply to the chosen lines
   * @param removeAfterMsTimeout if set, removes the highlight after waiting for these milliseconds
   */
  highlightLines(
    lines?: Array<number>,
    highlightCssClass: string = 'highlight-code-line',
    removeAfterMsTimeout?: number,
  ) {
    const decorations = lines?.map(lineNumber => ({
      range: new Range(lineNumber, 1, lineNumber, 1),
      options: { isWholeLine: true, className: highlightCssClass },
    }));
    return this.applyDecorations(decorations, removeAfterMsTimeout);
  }

  /**
   * Applies the CSS class to all the lines passed as input
   * @param editor the monaco editor
   * @param ranges the lines ranges to highlight
   * @param highlightCssClass the CSS class to apply to the chosen lines
   * @param removeAfterMsTimeout if set, removes the highlight after waiting for these milliseconds
   */
  highlightLineRanges(
    ranges: IntRanges,
    highlightCssClass: string = 'highlight-code-line',
    removeAfterMsTimeout?: number,
  ) {
    const decorations = ranges.ranges().map(range => ({
      range: new Range(range.lower(), 1, range.upper(), 1),
      options: { isWholeLine: true, className: highlightCssClass },
    }));
    return this.applyDecorations(decorations, removeAfterMsTimeout);
  }

  goToLine(
    line: number | undefined,
    revealInCenter: boolean = true,
    durationMs: number = 1,
  ) {
    const startLine = this.editor.getVisibleRanges()[0]?.startLineNumber ?? 1;
    const endLine = line ?? 1;
    const startTime = performance.now();

    const animateScroll = () => {
      const currentTime = performance.now();
      const progress = Math.min(1, (currentTime - startTime) / durationMs);
      const scrollToLine = Math.round(
        startLine + (endLine - startLine) * progress,
      );
      if (revealInCenter) this.editor.revealLineInCenter(scrollToLine);
      else this.editor.revealLineNearTop(scrollToLine);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  }

  /**
   * this will remove the version number from the urn,
   * in order to compare the urn from newer versions
   * @param urn
   * @private
   */
  private removeVersionFromUrn(urn: string) {
    return urn.replace(/:\d+/g, ':');
  }

  /**
   * Returns the index of the first line containing the input value
   * @param value the value that will be looked for
   * @returns the line number (-1 in case the value is not found)
   */
  findLineContainingId(value: string): number {
    const noVersionValue = this.removeVersionFromUrn(value);
    const model = this.editor.getModel();
    if (model) {
      const allLines = model.getLinesContent();
      return allLines.findIndex(line => {
        const id = this.extractId(line);
        if (id) {
          const noVersionId = this.removeVersionFromUrn(id);
          return noVersionId === noVersionValue;
        }
        return false;
      });
    }
    return -1; // Return -1 if the value is not found
  }

  private extractId(line: string): string | undefined {
    const regex = /^id: ([^\"']*|\".*\"|'.*')$/;
    const parsed = regex.exec(line.trim());
    if (parsed && typeof parsed[1] !== 'undefined') {
      return parsed[1].replaceAll("'", '').replaceAll('"', '');
    }
    return undefined;
  }

  private parseMainLine(
    visitor: SectionsVisitor,
    line: string,
    lineIdx: number,
  ): SectionsVisitor {
    visitor.id = this.extractId(line) ?? visitor.id;
    if (visitor.id && line === 'components:') {
      visitor.sections = [
        ...visitor.sections,
        { lines: new IntRange(visitor.startIdx, lineIdx + 1), id: visitor.id },
      ];
      visitor.main = false;
      visitor.startIdx = lineIdx + 2;
      visitor.id = undefined;
    }
    return visitor;
  }

  private parseComponentLine(
    visitor: SectionsVisitor,
    line: string,
    lineIdx: number,
    linesCount: number,
  ): SectionsVisitor {
    visitor.id = this.extractId(line) ?? visitor.id;
    if (visitor.id && (line.startsWith('  -') || lineIdx === linesCount - 1)) {
      visitor.sections = [
        ...visitor.sections,
        {
          lines: new IntRange(
            visitor.startIdx,
            lineIdx === linesCount - 1 ? lineIdx + 1 : lineIdx,
          ),
          id: visitor.id,
        },
      ];
      visitor.startIdx = lineIdx + 1;
      visitor.id = undefined;
    }
    return visitor;
  }

  getSections(): Section[] {
    const model = this.editor.getModel();
    if (model) {
      const allLines = model.getLinesContent();

      const result: SectionsVisitor = allLines.reduce<SectionsVisitor>(
        (visitor, line, lineIdx) => {
          if (visitor.main) {
            return this.parseMainLine(visitor, line, lineIdx);
          }
          return this.parseComponentLine(
            visitor,
            line,
            lineIdx,
            allLines.length,
          );
        },
        { sections: [], main: true, id: undefined, startIdx: 1 },
      );
      return result.sections;
    }
    return [];
  }

  selectLinesOfOtherSections(urnToKeep: string): IntRanges {
    const noVersionUrn = this.removeVersionFromUrn(urnToKeep);
    const sections: Section[] = this.getSections();
    return new IntRanges(
      sections.flatMap(section =>
        this.removeVersionFromUrn(section.id) === noVersionUrn
          ? []
          : [section.lines],
      ),
    );
  }
}
